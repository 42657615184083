import Header from '../../components/Header';
import Solutions from '../../components/Solutions';
import Tech from '../../components/Tech';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';

import React, { useEffect } from 'react';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const handleScrollToElement = () => {
      const hash = location.hash.substring(1);
      if (hash) {
        const targetElement = document.getElementById(hash);
        if (targetElement) {

          setTimeout(() => {
            const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo({
              top: offsetTop,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    };
    if (document.readyState === 'complete') {
      handleScrollToElement();
    } else {
      window.addEventListener('load', handleScrollToElement);
      return () => window.removeEventListener('load', handleScrollToElement);
    }
  }, [location]);



  return (
    <>
      <div className="bg-cover bg-center min-h-screen" style={{ backgroundImage: "url('/assets/images/Fundo.png')" }}>
        <Header />
        <div className="pt-20 md:pt-56 container mx-auto px-4">
          <div className="text-white flex flex-col-reverse md:flex-row items-start md:items-center justify-center h-screen pt-24 pb-24 md:justify-between">
            <div className='pb-56'>
              <h1 className="text-3xl md:text-5xl font-semibold mb-4">
                Desenvolvimento ágil de<br />Software e Chatbots Inteligentes
              </h1>
              <p className="mb-6 max-w-xl">
                Somos a Flynx, uma fábrica de software especializada em entregas rápidas e eficientes. Desenvolvemos projetos em prazos curtos, com preços competitivos.
              </p>
              <a href="#solucoes" className="text-white text-lg transition font-semibold duration-300 ease-in-out rounded-md px-4 py-2 bg-gradient-to-r from-[#6B0BE8] to-[#328CD9] hover:from-[#5a0ac7] hover:to-[#2b75bc]">
                Saiba mais
              </a>
              <img className="mt-40" src="/assets/images/Parceiros-Flynx-Texto.png" alt="Parceiros Flynx" />
              <img className="mt-10" src="/assets/images/Logos-Parceiros.png" alt="Parceiros" />
            </div>
            <div className="mb-10 md:mb-0">
              <img src="/assets/images/Celular.png" alt="Chat" className="hidden md:flex max-w-xs md:w-full -mt-72 mx-auto" />
            </div>
          </div>
        </div>
      </div>
      <Solutions />
      <Tech />
      <Contact />
      <Footer />
    </>
  );
  }  
export default App;
