import React, { useState } from 'react';

const Contact: React.FC = () => {

    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const mailtoLink = `mailto:contato@flynx.com.br?subject=Mensagem de ${nome}&body=${mensagem} %0D%0A%0D%0A Nome: ${nome} %0D%0A Telefone: ${telefone} %0D%0A E-mail: ${email}`;
        window.location.href = mailtoLink;
    };

    return (
        <div id="contatos" className="h-screen flex items-center justify-center bg-white p-10">
            <div className="flex w-full max-w-6xl">
                <div className="flex flex-col w-full md:w-1/2 pr-10">
                        <div className="text-4xl font-bold text-left mb-6">
                            Pronto para <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">transformar</span><br />sua visão em realidade?
                        </div>
                        <div className="mt-6 text-left text-lg mb-8">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">Entre em contato conosco</span> e descubra como a <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">Flynx</span> pode impulsionar a inovação em seu negócio. Estamos ansiosos para colaborar e <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">criar soluções</span> personalizadas.
                        </div>

                        <div className="mt-20 flex flex-col md:flex-row gap-8 mb-6">
                            <div className="flex items-center">
                                <img src="/assets/images/Email.png" alt="E-mail" className="h-12 w-12 mr-4" />
                                <span className="text-lg"><b>E-mail</b><br />contato@flynx.com.br</span>
                            </div>
                            <div className="flex items-center">
                                <img src="/assets/images/Telefone.png" alt="Telefone" className="h-12 mr-4 w-12" />
                                <span className="text-lg"><b>Telefone</b><br />+55 (41) 3798-7111</span>
                            </div>
                        </div>
                </div>
                <div className="hidden md:block w-1/2 bg-[#1C1D1E] p-6 rounded-xl">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Seu nome completo"
                                    className="w-full bg-[#262626] text-white rounded-lg p-3 border border-[#333333] focus:ring-[#6B0BE8] focus:border-[#6B0BE8]"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="(00) 0000-0000"
                                    className="w-full bg-[#262626] text-white rounded-lg p-3 border border-[#333333] focus:ring-[#6B0BE8] focus:border-[#6B0BE8]"
                                    value={telefone}
                                    onChange={(e) => setTelefone(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="email"
                                    placeholder="Digite seu e-mail empresarial"
                                    className="w-full bg-[#262626] text-white rounded-lg p-3 border border-[#333333] focus:ring-[#6B0BE8] focus:border-[#6B0BE8]"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-6">
                                <textarea
                                    placeholder="Em que posso te ajudar?"
                                    className="w-full bg-[#262626] text-white rounded-lg p-3 border border-[#333333] focus:ring-[#6B0BE8] focus:border-[#6B0BE8]"
                                    rows={4}
                                    value={mensagem}
                                    onChange={(e) => setMensagem(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-between mb-4">
                                <button
                                    type="submit"
                                    className="bg-gradient-to-r from-[#6B0BE8] to-[#328CD9] text-white py-2 px-4 rounded-lg hover:bg-gradient-to-l focus:outline-none focus:ring-2 focus:ring-[#6B0BE8]"
                                >
                                    Enviar mensagem
                                </button>
                            </div>
                        </form>
                </div>

            </div>
        </div>
    );
};

export default Contact;
