/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="h-96 " style={{ backgroundImage: "url('/assets/images/Rodape.png')" }}>
            <div className="pt-32 max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <div className="mb-6 lg:mb-0">
                        <img src="/assets/images/Logo.png" alt="Flynx logo" className="h-10 w-auto mb-2" />
                        <p className='mt-6 text-white max-w-sm'>A Flynx é uma fábrica de software ágil, comprometida em proporcionar resultados excepcionais em prazos curtos e com preços competitivos.</p>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="mb-6 md:mb-0 md:mr-12">
                            <h3 className="text-white font-semibold mb-2">Links</h3>
                            <ul>
                                <li><a className="text-gray-400" href="/">Início</a></li>
                                <li><a className="text-gray-400" href="#solucoes">Soluções</a></li>
                                <li><a className="text-gray-400" href="#contatos">Contato</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-white font-semibold mb-2">Contatos</h3>
                            <ul>
                                <li className="text-gray-400">contato@flynx.com.br</li>
                                <li className="text-gray-400">+55 (41) 3798-7111</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-8 border-gray-700" />
                <div className="flex items-center justify-between">
                    <p className='text-white'>&copy; 2024 - Flynx - Todos os direitos reservados.</p>
                    <div className="flex items-center gap-4">
                        <a href='https://www.instagram.com/flynxdev' target='_blank' rel="noreferrer">
                            <img src="/assets/images/Instagram.png" alt="Logo do Instagram" className="h-6 w-6 mb-2" />
                        </a>

                        <a href='https://wa.me/554137987111?text=Ol%C3%A1%21%20Encontrei%20seu%20contato%20atrav%C3%A9s%20do%20site%20e%20estou%20muito%20interessado%20em%20conhecer%20mais%20sobre%20os%20servi%C3%A7os%20de%20TI%20que%20voc%C3%AA%20oferece.%20Poderia%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es%20sobre%20as%20solu%C3%A7%C3%B5es%20dispon%C3%ADveis%3F%20Obrigado%21' target='_blank' rel='noreferrer'>
                            <img src="/assets/images/Whatsapp.png" alt="Logo do Whatsapp" className="h-6 w-6 mb-2" />
                        </a>

                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
