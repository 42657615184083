import React from 'react';
import './styles.css';

const Header: React.FC = () => {
  return (
    <div className="fixed top-0 w-full z-10">
      <div className="mt-8 md:mt-8 w-full md:w-3/4 h-20 md:h-20 bg-gradient-to-r from-[#21253E] via-[#1D1E20] to-[#1F2231] rounded-full shadow-xl flex justify-between items-center px-4 md:px-5 mx-auto">
        <div className='ml-4 md:ml-8'>
          <img src="/assets/images/Logo.png" alt="Logo" className="h-10 w-auto" />
        </div>
        <div className="flex items-center mr-4 md:mr-8 gap-4 md:gap-10">
          <a href="/" className="custom-underline text-white text-base md:text-lg">Início</a>
          <a href="#solucoes" className="custom-underline text-white text-base md:text-lg">Soluções</a>
          <a href="#contatos" className="custom-underline text-white text-base md:text-lg">Contato</a>
          <a href="https://wa.me/554137987111?text=Ol%C3%A1%21%20Encontrei%20seu%20contato%20atrav%C3%A9s%20do%20site%20e%20estou%20muito%20interessado%20em%20conhecer%20mais%20sobre%20os%20servi%C3%A7os%20de%20TI%20que%20voc%C3%AA%20oferece.%20Poderia%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es%20sobre%20as%20solu%C3%A7%C3%B5es%20dispon%C3%ADveis%3F%20Obrigado%21" target="_blank" className="hidden md:block text-white cursor-pointer text-base md:text-lg transition font-semibold duration-300 ease-in-out rounded-md px-3 md:px-4 py-1 md:py-2 bg-gradient-to-r from-[#6B0BE8] to-[#328CD9] hover:from-[#5a0ac7] hover:to-[#2b75bc]" rel="noreferrer">
            Entre em contato
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
