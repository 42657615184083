import React from 'react';

const Tech: React.FC = () => {
    const lista = ["Docker", "Java", "MySQL", "Node", "React", "PHP", "Kubernetes"];

    return (
        <div className="hidden md:flex flex-col items-center justify-center bg-[#f4f4f4] p-4 relative">
            <div className="mt-8 text-4xl font-bold text-center mb-6">
                Conheça as <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">tecnologias</span> que utilizamos
            </div>
            <div className="flex overflow-x-auto">
                <div className="flex justify-center items-center gap-20 pt-10 pb-10 whitespace-nowrap">
                    {lista.map((item) => (
                        <img key={item} src={`/assets/images/${item}.png`} alt={item} className="h-8" />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tech;
