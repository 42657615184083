import React from 'react';

const Solutions: React.FC = () => {
    return (
        <div id="solucoes" className="md:h-screen bg-white overflow-hidden p-10">
            <div className="max-w-7xl mx-auto h-full">
                <div className="mb-14 md:mb-28 text-3xl md:text-4xl font-bold">
                    Transforme seu negócio<br />com nossas <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#6B0BE8] to-[#328CD9]">soluções inovadoras</span>
                </div>

                <div className="flex">
                    <div className="flex flex-col space-y-8 w-3/3 md:w-1/2">
                        <div className="bg-white border-gray-200 border p-3 rounded-lg shadow-md h-56 md:h-44">
                            <img src={`/assets/images/IA.png`} alt="IA" className="h-14 mb-4" />

                            <h2 className="text-black text-lg font-bold">Integração de IA</h2>
                            <p className='text-gray-500'>Oferemos expertise em integração de IA, permitindo que você crie e treine seus próprios assistentes virtuais.</p>
                        </div>
                        <div className="bg-white border-gray-200 border p-3 rounded-lg shadow-md h-56 md:h-44">
                            <img src={`/assets/images/Processos.png`} alt="Processos" className="h-14 mb-4" />

                            <h2 className="text-black text-lg font-bold">Automatização de processos</h2>
                            <p className='text-gray-500'>Da concepção à implementação, dominamos o ciclo DevOps para garantir eficiência e confiabilidade.</p>
                        </div>

                        <div className="md:hidden bg-white border-gray-200 border p-3 rounded-lg shadow-md h-56 md:h-44">
                            <img src={`/assets/images/Computador.png`} alt="Desenvolvimento" className="h-14 mb-4" />

                            <h2 className="text-black text-lg font-bold">Desenvolvimento de softwares e aplicativos</h2>
                            <p className='text-gray-500'>Entregamos projetos com uma qualidade indiscutível. Seja para aplicativos web, mobile ou desktop.</p>
                        </div>
                    </div>
                    <div className="hidden ml-4 w-1/2 h-96 border-gray-200 border bg-cover bg-no-repeat rounded-lg shadow-md md:flex flex-col justify-end p-4" style={{ backgroundImage: "url('/assets/images/Notebook.png')" }}>
                        <h2 className="text-black text-lg font-bold">Desenvolvimento de softwares e aplicativos</h2>
                        <p className="text-black mb-4">Entregamos projetos com uma qualidade indiscutível. Seja para aplicativos web, mobile ou desktop, a Flynx será sua parceira ideal.</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Solutions;
